import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Listings from '@components/listings';

export default function Equipment({ data, pageContext, location }) {
  return (
    <Listings
      location={location}
      page="/equipment"
      slug={pageContext.slug ? pageContext.slug.replace(/\//g, ``) : ``}
      listings={data.equipment.edges}
      counts={{
        equipment: data.equipment.totalCount,
        attachments: data.attachmentsCount.totalCount,
        forSale:
          data.forSaleCount.totalCount +
          data.equipmentForSaleCount.totalCount +
          data.attachmentsForSaleCount.totalCount,
      }}
    />
  );
}

Equipment.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  query equipment($slug: String!) {
    equipment: allPrismicEquipment(
      filter: { data: { category: { uid: { regex: $slug } } } }
      sort: { fields: data___title___text }
    ) {
      totalCount
      edges {
        node {
          id
          uid
          type
          data {
            title {
              text
            }
            copy {
              text
            }
            image {
              alt
              url
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyPrismicImageFluid
              }
            }
            coming_soon
            for_sale
            selling_price {
              text
            }
            four_hour_price {
              text
            }
            day_price {
              text
            }
            week_price {
              text
            }
            month_price {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategories {
                  data {
                    name
                  }
                }
              }
            }
            sub_category {
              uid
              document {
                ... on PrismicSubCategories {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    attachmentsCount: allPrismicAttachments(
      filter: { data: { category: { uid: { regex: $slug } } } }
    ) {
      totalCount
    }
    forSaleCount: allPrismicForSale(
      filter: { data: { category: { uid: { regex: $slug } } } }
    ) {
      totalCount
    }
    equipmentForSaleCount: allPrismicEquipment(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      totalCount
    }
    attachmentsForSaleCount: allPrismicAttachments(
      filter: {
        data: { for_sale: { eq: "Yes" }, category: { uid: { regex: $slug } } }
      }
    ) {
      totalCount
    }
  }
`;
